import { render, staticRenderFns } from "./TabBar.vue?vue&type=template&id=5b13b41a&scoped=true"
import script from "./TabBar.vue?vue&type=script&lang=js"
export * from "./TabBar.vue?vue&type=script&lang=js"
import style0 from "./TabBar.vue?vue&type=style&index=0&id=5b13b41a&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b13b41a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b13b41a')) {
      api.createRecord('5b13b41a', component.options)
    } else {
      api.reload('5b13b41a', component.options)
    }
    module.hot.accept("./TabBar.vue?vue&type=template&id=5b13b41a&scoped=true", function () {
      api.rerender('5b13b41a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ArgumentedReality/TabBar.vue"
export default component.exports