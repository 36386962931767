<template>
<div
  class="v_ar_index d-flex align-center flex-column"
>
  <TabBar
    class="flex-shrink-0"
  ></TabBar>
  <div
    class="content_group"
  >
    <RouterView></RouterView>
  </div>
</div>
</template>

<script>
import TabBar from '@/views/ArgumentedReality/TabBar'
import { mapState } from 'vuex'
export default {
  components: {
    TabBar,
  },
  data () {
    return {
      tabType: '',
      pageType: 'scene',
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      user: state => state.user,
      arPermission: state => state.app.arPermission,
    }),
  },
  watch: {
    arPermission (nv, ov) {
      console.log(nv, ov)
    }
  },
  methods: {
    init () {
      if (this.user) {
        if (!this.user.areditflag) {
          this.$router.push({
            name: 'fun_task_index'
          })
          return
        }
      }
      this.$router.push({
        name: 'AREdit',
        params: {
          id: 'null'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content_group {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>