<template>
<div
  class="v_ar_tabbar d-flex align-center justify-space-between bbox"
>
  <div
    class="left_group d-flex align-center"
  >
    <template
      v-for="(item, index) in tabList"
    >
      <div
        :key="index"
        class="tab_group bbox d-flex align-center cp"
        :class="{
          [item.type]: true,
          active: pageType === item.type
        }"
        @click.stop="changeType(item.type)"
      >
        <div
          class="icon"
        >
        </div>
        <div
          class="name"
        >
          {{ item.name }}
        </div>
      </div>
    </template>

  </div>
  <div
    class="right_group"
  ></div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {
  data () {
    return {
      tabList: [
        {
          name: '场景',
          type: 'scene'
        },
        {
          name: '资产',
          type: 'asset'
        },
        {
          name: '方案',
          type: 'scheme'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      pageType: state => state.ar.pageType
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.$route.name === 'ARScheme') {
        store.commit('setArPageType', 'scheme')
      } else {
        store.commit('setArPageType', 'scene')
      }
    },
    changeType (type) {
      console.log(this.pageType)
      console.log(type)
      if (this.pageType !== type) {
        store.commit('setArPageType', type)
      }
      if (type === 'scheme') {
        this.$router.push({
          name: 'ARScheme'
        })
      } else {
        console.log(this.$route)
        if (this.$route.name !== 'AREdit') {
          this.$router.push({
            name: 'AREdit',
            params: {
              id: 'null'
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@mixin icon ($name) {
  &.#{$name} {
    &.active {
      .icon {
        background: {
          image: url(~@/assets/images/ar/#{ $name }_on.png);
          size: 100%;
        }
      }
    }
    .icon {
      background: {
        image: url(~@/assets/images/ar/#{ $name }.png);
        size: 100%;
      }
    }
  }
}
.v_ar_tabbar {
  width: 100%;
  height: 60px;
  padding: 0 40px;
  background-color: #fff;
  border-bottom: 1px solid #F4F4F4;
  .left_group {
    height: 100%;
    .tab_group {
      @include icon(scene);
      @include icon(asset);
      @include icon(scheme);
      height: 100%;
      border-bottom: 2px solid transparent;
      margin-right: 50px;
      &.active {
        border-bottom-color: $main_blue;
        .name {
          color: $main_blue;
        }
      }
      .icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
      .name {
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>
