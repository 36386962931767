var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_ar_tabbar d-flex align-center justify-space-between bbox",
    },
    [
      _c(
        "div",
        { staticClass: "left_group d-flex align-center" },
        [
          _vm._l(_vm.tabList, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "tab_group bbox d-flex align-center cp",
                  class: {
                    [item.type]: true,
                    active: _vm.pageType === item.type,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeType(item.type)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon" }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "right_group" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }